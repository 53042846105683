<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">กรุณารอสักครู่...</p>
          </div>
        </template>
        <b-row>
          <b-col md="12">
            <div class="card p-2 bg-blue">
              <div class="d-lg-flex justify-content-between align-items-center">
                <h3 class="mb-0 text-white">
                  {{ $t("orderList") }}
                </h3>

                <div class="d-flex py-1 py-lg-0">
                  <button
                    v-if="details.status === 2"
                    class="btn btn-sun mb-1 mr5"
                    @click="(details.status = 1), UpdateDetail()"
                  >
                    <i class="fal fa-clock" /> {{ $t("ChangeToWaitVerify") }}
                  </button>
                  <button
                    v-if="details.status === 1"
                    class="btn btn-success mb-1 mr5"
                    @click="Approve(details.status)"
                  >
                    <i class="fal fa-check-circle" /> {{ $t("ApproveList") }}
                  </button>
                  <button
                    v-if="details.status === 3"
                    class="btn btn-success mb-1 mr5"
                    @click="Approve(details.status)"
                  >
                    <i class="fal fa-check-circle" /> {{ $t("orderSuccess") }}
                  </button>

                  <button
                    v-if="details.status < 4 && details.status !== 7"
                    class="btn btn-danger mb-1 mr5"
                    @click="CancelOrder()"
                  >
                    <i class="fal fa-times" /> {{ $t("cancelOrder") }}
                  </button>

                  <button
                    v-if="details.delivery_type == 2 && details.status !== 7"
                    class="btn btn-warning mb-1 mr5"
                    @click="(details.delivery_type = 1), UpdateDetail()"
                  >
                    <span class="text-dark">
                      <i class="fal fa-shipping-fast" />
                      {{ $t("ChangetoSendCar") }}</span
                    >
                  </button>

                  <button
                    v-if="details.delivery_type == 1 && details.status !== 7"
                    class="btn btn-warning mb-1 mr5"
                    @click="(details.delivery_type = 2), UpdateDetail()"
                  >
                    <span class="text-dark">
                      <i class="fal fa-ship" /> {{ $t("ChangetoSendShip") }}
                    </span>
                  </button>
                </div>
              </div>

              <div class="card p-2 mt-1">
                <div class="anan-order">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-md-12">
                      <table>
                        <tbody>
                          <tr class="anan-order-row">
                            <td class="" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("orderNumber") }}
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                ORD-{{ details.orderId_text }}
                              </p>
                            </td>
                          </tr>
                          <tr class="anan-order-row">
                            <td class="" style="width: 40%">
                              <p class="txt-odr mb-0">Ref.</p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                {{ details.username }}#{{
                                  details.orderId_text
                                }}
                                {{ details.delivery_type == 1 ? "" : "(SEA)" }}
                                {{ details.shops.some(ele => ele.packbox === true) && details.shops.some(ele
                                  =>
                                  ele.checkproduct === true) ? '(QC)(打木架)' : details.shops.some(ele => ele.packbox ===
                                    true) ?
                                  '(打木架)' : details.shops.some(ele => ele.checkproduct === true) ? '(QC)' : '' }}
                              </p>
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td class="" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("customerCode") }} :
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                <b-link :to="{ name: 'admin-customer-detail' }">
                                  {{ details.username }}
                                </b-link>
                              </p>
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td style="width: 40%">
                              <p class="txt-odr mb-0">{{ $t("mnemonic") }} :</p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                {{ details.note }}
                              </p>
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("mnemonicPhrase") }} :
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <!-- <p class="txt-odr mb-0">
                            {{ details.note_admin }}
                          </p> -->
                              <textarea
                                ref="textarea"
                                v-model="details.note_admin"
                                rows="1"
                                class="ant-input ghost ant-input-sm sss"
                                @input="resize"
                                @change="
                                  updateKeyword(details._id, details.note_admin)
                                "
                              />
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td class="" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("deliveryType") }} :
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <div v-if="details.delivery_type == 2">
                                {{ $t("byship") }} <i class="fas fa-ship" />
                              </div>

                              <div v-else>
                                {{ $t("byTruck") }}
                                <i class="fas fa-shipping-fast" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-6">
                      <table>
                        <tbody>
                          <tr class="anan-order-row">
                            <td class="text-right" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("status") }}
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                <b-badge
                                  v-if="details.status === 1"
                                  variant="light-warning"
                                >
                                  {{ $t("pendingReview") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 2"
                                  variant="light-danger"
                                >
                                  {{ $t("awaitingPayment") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 3"
                                  variant="light-info"
                                >
                                  {{ $t("paidAmount") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 4"
                                  variant="light-success"
                                >
                                  {{ $t("orderSuccess") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 5"
                                  variant="light-info"
                                >
                                  {{ $t("fullTrack") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 6"
                                  variant="light-info"
                                >
                                  {{ $t("completeWarehouseChina") }}
                                </b-badge>

                                <b-badge
                                  v-if="details.status === 7"
                                  variant="light-danger"
                                >
                                  {{ $t("cancel") }}
                                </b-badge>
                                <span
                                  v-if="details.status === 7"
                                  class="text-danger"
                                >
                                  {{
                                    details.cancel_remark
                                      ? `*
                                  ${details.cancel_remark}`
                                      : ""
                                  }}
                                  {{
                                    details.cancel_by
                                      ? `${$t("canceledBy")}
                                  ${details.cancel_by}`
                                      : ""
                                  }}</span
                                >
                                <b-badge
                                  v-if="details.status === 8"
                                  variant="light-warning"
                                >
                                  {{ $t("leftChinaWarehouse") }}
                                </b-badge>
                                <b-badge
                                  v-if="details.status === 9"
                                  variant="light-warning"
                                >
                                  {{ $t("arrivedThaiWarehouse") }}
                                </b-badge>
                                <b-badge
                                  v-if="details.status === 10"
                                  variant="light-warning"
                                >
                                  {{ $t("completeWarehouseThaiExit") }}
                                </b-badge>
                              </p>
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td class="text-right" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("transactionDate") }}
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                {{ details.created_at }}
                              </p>
                            </td>
                          </tr>

                          <tr class="anan-order-row">
                            <td class="text-right" style="width: 40%">
                              <p class="txt-odr mb-0">
                                {{ $t("exRate") }}
                              </p>
                            </td>
                            <td class="text-left pl-1" style="width: 60%">
                              <p class="txt-odr mb-0">
                                ¥1 = {{ details.rate_yuan }} ฿
                                <span>
                                  <feather-icon
                                    icon="Edit3Icon"
                                    size="18"
                                    class="cursor-pointer text-primary"
                                    @click="showModal"
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-6 my-2 px-2">
                      <h5>{{ $t("orderNoteUser") }}</h5>

                      <b-form-textarea
                        v-model="details.remark_customer"
                        row="2"
                        disabled
                        @change="UpdateDetail()"
                      />
                    </div>

                    <div class="col-md-6 my-2 px-2">
                      <h5>{{ $t("orderNoteAdmin") }}</h5>

                      <b-form-textarea
                        v-model="details.remark_admin"
                        row="2"
                        @change="UpdateDetail()"
                      />
                    </div>

                    <div class="col-md-12">
                      <p>
                        {{ $t("inspector") }} :
                        {{ details.inspector ? details.inspector : "-" }}
                      </p>
                      <p>
                        {{ $t("lastEditedBy") }} :
                        <b-link
                          :to="{
                            name: 'admin-employee-edit',
                            params: { id: details.edit_byid },
                          }"
                        >
                          {{ details.edit_by ? details.edit_by : "-" }}
                        </b-link>
                      </p>

                      <p>{{ $t("lastEditDate") }} : {{ details.updated_at }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col md="12" v-if="details.status >= 6 && details.status != 7">
            <div
              class="card p-2 d-flex justify-content-center align-items-center"
            >
              <img
                src="/checkorder.jpg"
                alt=""
                width="75%"
                height="75%"
                style="border-radius: 10px"
              />
            </div>
          </b-col>

          <b-col md="12">
            <div class="card p-2">
              <h3 class="mb-0 font-weight-bolder">
                {{ $t("listProducts") }}
              </h3>

              <div
                v-for="(item, index_shop) in details.shops"
                :key="index_shop"
                class="mb-2 table-container"
              >
                <div class="mt-1">
                  <table width="1000px">
                    <tbody>
                      <tr class="anan-order-row">
                        <td style="width: 170px">
                          <p class="txt-odrx mb-0">
                            {{ $t("productName") }} / {{ $t("productDetails") }}
                          </p>
                        </td>
                        <td class="" style="width: 400px">
                          <p class="txt-odrx mb-0 d-flex align-items-center">
                            <span class="mr5">{{ $t("type") }}</span>
                            <v-select
                              v-model="item.product_type"
                              :options="typesOp"
                              label="pro_name"
                              size="sm"
                              class="w-100"
                              @input="
                                UpdateDetail(),
                                  UpDateProduct(
                                    item.product_type,
                                    item._id,
                                    item.packbox,
                                    item.checkproduct
                                  )
                              "
                            />
                          </p>
                        </td>

                        <td class="" style="width: 50px">
                          <b-form-checkbox
                            v-model="item.packbox"
                            name="check-button"
                            :value="true"
                            @change="
                              UpdateDetail(details.shops[index_shop].packbox),
                                UpDateProduct(
                                  item.product_type,
                                  item._id,
                                  item.packbox,
                                  item.checkproduct
                                )
                            "
                          >
                            <p class="txt-odrx mb-0">
                              {{ $t("woodPacking") }}
                            </p>
                          </b-form-checkbox>
                        </td>

                        <td class="" style="width: 50px">
                          <b-form-checkbox
                            v-model="item.checkproduct"
                            name="check-button"
                            :value="true"
                            @change="
                              UpdateDetail(
                                details.shops[index_shop].checkproduct
                              ),
                                UpDateProduct(
                                  item.product_type,
                                  item._id,
                                  item.packbox,
                                  item.checkproduct
                                )
                            "
                          >
                            <p class="txt-odrx mb-0">
                              {{ $t("checkOrder") }}
                            </p>
                          </b-form-checkbox>
                        </td>

                        <!-- <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.re_wrap"
                          name="check-button"
                          :value="true"
                          @change="UpdateDetail(details.shops[index_shop].re_wrap)"
                        >
                          <p class="txt-odrx mb-0">
                            ห่อใหม่
                          </p>
                        </b-form-checkbox>
                      </td>

                      <td
                        class=""
                        style="width: 10%;"
                      >
                        <b-form-checkbox
                          v-model="item.cashOndelivery"
                          name="check-button"
                          :value="true"
                          @change="UpdateDetail(details.shops[index_shop].cashOndelivery)"
                        >
                          <p class="txt-odrx mb-0">
                            เก็บเงินปลายทาง
                          </p>
                        </b-form-checkbox>
                      </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table
                    style="width: 100%; min-width: 1100px"
                    class="table-width-1"
                  >
                    <!-- colgroup -->
                    <colgroup>
                      <col style="width: 60%" />
                      <col style="width: 15%" />
                      <col style="width: 10%" />
                      <col style="width: 10%" />
                      <col style="width: 10%" />
                      <col style="width: 10%" />
                    </colgroup>

                    <thead>
                      <tr
                        style="
                          background-color: rgb(215, 233, 250);
                          border-radius: 10px !important;
                        "
                      >
                        <th class="p-1">
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <span>
                              {{ index_shop + 1 }}. {{ item.storename }}</span
                            >

                            <b-form-input
                              v-model="item.tag_id"
                              type="text"
                              size="sm"
                              class="ml-1 w-50"
                              @change="UpdateDetail()"
                            />
                          </div>
                        </th>

                        <th class="text-center">
                          {{ $t("quantity") }}
                        </th>

                        <th class="text-center">{{ $t("PerItem") }} [¥]</th>

                        <th class="text-center">{{ $t("total") }} [¥]</th>

                        <th class="text-center">{{ $t("total") }} [฿]</th>

                        <th class="text-center">
                          {{ $t("manage") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(itemsproduct, index_product) in item.products"
                        :key="index_product"
                        class="anan-order-row"
                        :style="`background-color: ${itemsproduct.colorfield};`"
                      >
                        <td>
                          <div class="d-flex align-items-center p-1">
                            <div class="d-flex align-items-center">
                              <span
                                >{{ index_shop + 1 }}.{{
                                  index_product + 1
                                }}</span
                              >

                              <img
                                :src="
                                  itemsproduct.color_img
                                    ? itemsproduct.color_img
                                    : itemsproduct.picture
                                "
                                :alt="itemsproduct.picture"
                                height="50px"
                                class="ml-2 rounded mr5 cursor-pointer"
                                @click="
                                  showPicture(
                                    itemsproduct.color_img
                                      ? itemsproduct.color_img
                                      : itemsproduct.picture
                                  )
                                "
                              />

                              <div>
                                <a :href="itemsproduct.url" target="_blank">
                                  <h5 class="mb-0 JB57cn2">
                                    {{ itemsproduct.name }}
                                  </h5>
                                </a>
                                <p v-if="itemsproduct.color_title" class="mb-0">
                                  {{ $t("color") }} :
                                  {{
                                    itemsproduct.color_title
                                      ? itemsproduct.color_title
                                      : "-"
                                  }}
                                </p>
                                <p v-if="itemsproduct.size_title" class="mb-0">
                                  {{ $t("size") }} :
                                  {{
                                    itemsproduct.size_title
                                      ? itemsproduct.size_title
                                      : "-"
                                  }}
                                </p>
                                <p
                                  v-if="itemsproduct.remark_product"
                                  class="mb-0"
                                  style="font-weight: bold; color: red"
                                >
                                  {{ $t("remarks") }} :
                                  {{
                                    itemsproduct.remark_product
                                      ? itemsproduct.remark_product
                                      : ""
                                  }}
                                </p>
                                <p
                                  v-if="itemsproduct.fileimg"
                                  style="font-weight: bold"
                                  class="mb-0"
                                >
                                  {{ $t("attachedFile") }}:

                                  <img
                                    :src="`https://api.anan-cargo.com/api/Order/getimg/${itemsproduct.fileimg}`"
                                    alt="Preview"
                                    width="50"
                                    class="text-center"
                                    @click="
                                      showPicture(
                                        `https://api.anan-cargo.com/api/Order/getimg/${itemsproduct.fileimg}`
                                      )
                                    "
                                  />
                                  <!-- <img
                            :src="`http://127.0.0.1:4444/api/Order/getimg/${itemsproduct.fileimg}`"
                            alt="Preview"
                            width="50"
                            class="text-center"
                            @click="showPicture(`http://127.0.0.1:4444/api/Order/getimg/${itemsproduct.fileimg}`)"
                          > -->
                                </p>
                                <p
                                  v-if="itemsproduct.remark"
                                  style="font-weight: bold"
                                >
                                  {{ $t("remarks") }}: {{ itemsproduct.remark }}
                                </p>
                                <div
                                  v-if="
                                    itemsproduct.more_payment.remark ||
                                    itemsproduct.more_payment.datas.length > 0
                                  "
                                  class="text-danger"
                                >
                                  <p
                                    v-if="itemsproduct.more_payment.remark"
                                    class="mb-0"
                                  >
                                    {{ $t("remarks") }} :
                                    {{ itemsproduct.more_payment.remark }}
                                  </p>
                                  <div
                                    v-if="
                                      itemsproduct.more_payment.datas.length > 0
                                    "
                                  >
                                    <p
                                      v-for="(
                                        item_morepay, index
                                      ) in itemsproduct.more_payment.datas"
                                      :key="index"
                                      class="mb-0"
                                    >
                                      {{
                                        item_morepay.payment_type == 1
                                          ? "เก็บเงินเพิ่ม"
                                          : "คืนเงิน"
                                      }}
                                      : {{ Commas(item_morepay.amount) }} บาท
                                    </p>
                                    <p
                                      v-if="
                                        itemsproduct.more_payment.datas
                                          .filter(
                                            (ele) => ele.payment_type == 1
                                          )
                                          .reduce(
                                            (acc, ele) => acc + ele.amount,
                                            0
                                          ) >
                                        itemsproduct.more_payment.datas
                                          .filter(
                                            (ele) => ele.payment_type == 2
                                          )
                                          .reduce(
                                            (acc, ele) => acc + ele.amount,
                                            0
                                          )
                                      "
                                      style="font-weight: 800"
                                    >
                                      {{ $t("extraMoney") }} :
                                      {{
                                        Commas(
                                          itemsproduct.more_payment.datas
                                            .filter(
                                              (ele) => ele.payment_type == 1
                                            )
                                            .reduce(
                                              (acc, ele) => acc + ele.amount,
                                              0
                                            ) -
                                            itemsproduct.more_payment.datas
                                              .filter(
                                                (ele) => ele.payment_type == 2
                                              )
                                              .reduce(
                                                (acc, ele) => acc + ele.amount,
                                                0
                                              )
                                        )
                                      }}
                                      บาท
                                    </p>
                                    <p v-else>
                                      {{ $t("totalRefunds") }} :
                                      {{
                                        Commas(
                                          itemsproduct.more_payment.datas
                                            .filter(
                                              (ele) => ele.payment_type == 2
                                            )
                                            .reduce(
                                              (acc, ele) => acc + ele.amount,
                                              0
                                            ) -
                                            itemsproduct.more_payment.datas
                                              .filter(
                                                (ele) => ele.payment_type == 1
                                              )
                                              .reduce(
                                                (acc, ele) => acc + ele.amount,
                                                0
                                              )
                                        )
                                      }}
                                      บาท
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- {{ details.shops.length }} -->
                              <!-- {{ index_shop === 0 ? index_product : index_product + details.shops[index_shop -1].products.length }} -->
                              <!-- {{ ProductShop[index_shop === 0 ? index_product : index_product + details.shops[index_shop -1].products.length] }} -->
                              <div class="w-100 ml-1">
                                <v-select
                                  v-model="
                                    ProductShop[index_shop][index_product]
                                  "
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  label="text"
                                  :options="ShopList"
                                  :reduce="(ele) => ele.value"
                                  class=""
                                  @input="
                                    ChangeShop(
                                      index_shop,
                                      index_product,
                                      ProductShop[index_shop][index_product]
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </td>

                        <td
                          class="text-center pl-1 pr-1"
                          style="vertical-align: middle"
                        >
                          <div class="">
                            <b-form-input
                              v-if="details.status === 1"
                              v-model="itemsproduct.quantity"
                              type="number"
                              class="w-100 text-center"
                              :style="{
                                marginTop:
                                  itemsproduct.quantity !==
                                  itemsproduct.quantity_cus
                                    ? '22px'
                                    : '0',
                              }"
                              @change="ChangePrice()"
                            />
                            <p v-else class="mb-0">
                              {{
                                Commas(
                                  itemsproduct.quantity
                                    ? itemsproduct.quantity
                                    : 0
                                )
                              }}
                            </p>
                            <small
                              v-if="
                                itemsproduct.quantity !==
                                itemsproduct.quantity_cus
                              "
                              class="text-danger"
                            >
                              จำนวนที่ลูกค้าสั่ง {{ itemsproduct.quantity_cus }}
                            </small>
                          </div>
                        </td>

                        <td
                          class="text-center pl-1 pr-1"
                          style="vertical-align: middle"
                        >
                          <b-form-input
                            v-if="details.status === 1"
                            v-model="itemsproduct.price"
                            type="number"
                            class="w-100 text-center"
                            @change="ChangePrice()"
                          />
                          <p v-else class="mb-0">
                            {{
                              itemsproduct.price
                                ? Commas(Number(itemsproduct.price))
                                : "0.00"
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              Commas(
                                itemsproduct.quantity *
                                  Number(itemsproduct.price)
                              )
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              Commas(
                                itemsproduct.quantity *
                                  Number(itemsproduct.price) *
                                  details.rate_yuan
                              )
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <b-button
                            v-if="details.status >= 3 && details.status !== 7"
                            v-b-tooltip.hover.top="'ให้ลูกค้าชำระเงินก่อน!'"
                            variant="primary"
                            size="sm"
                            class="btn-icon rounded-circle mr5 sq-30"
                            @click="showPayment(index_shop, index_product)"
                          >
                            <i class="fas fa-sack-dollar" />
                          </b-button>

                          <b-button
                            variant="warning"
                            size="sm"
                            class="btn-icon rounded-circle mr5 sq-30 mt-1"
                            @click="showRemark2(index_shop, index_product)"
                          >
                            <i class="fas fa-comment-alt-exclamation" />
                          </b-button>

                          <b-button
                            variant="info"
                            size="sm"
                            class="btn-icon rounded-circle mr5 sq-30 mt-1"
                            @click="showColor(index_shop, index_product)"
                          >
                            <i class="fas fa-fill-drip" />
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <div class="card-info mt-1 table-width-1">
                    <div class="row">
                      <div class="col-md-8">
                        <!-- {{ item.postnumber }} -->
                        <span v-if="!item.show">
                          <span
                            v-for="ItPost in item.postnumber"
                            :key="ItPost"
                            @click="showPicturePost(ItPost.path_img)"
                          >
                            <span
                              v-for="ItPostImg in ItPost.path_img"
                              :key="ItPostImg"
                              class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                            >
                              <img
                                :src="GetImg('postnumber_img', ItPostImg)"
                                alt="asd"
                                class="rounded mr5"
                              />
                            </span>
                          </span>
                        </span>
                      </div>

                      <div class="col-md-4">
                        <div>
                          <table style="width: 100%">
                            <!-- colgroup -->
                            <colgroup>
                              <col style="width: 50%" />
                              <col style="width: 25%" />
                              <col style="width: 25%" />
                            </colgroup>

                            <tbody>
                              <tr>
                                <td>
                                  <p class="mb-0 font-weight-bolder">
                                    {{ $t("totalProductprice") }}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    class="mb-0 font-weight-bolder text-success"
                                  >
                                    ¥
                                    {{
                                      Commas(
                                        item.products.reduce(
                                          (acc, ele) =>
                                            acc + ele.price * ele.quantity,
                                          0
                                        )
                                      )
                                    }}
                                  </p>
                                </td>
                                <td class="text-right">
                                  <p
                                    class="mb-0 font-weight-bolder text-primary"
                                  >
                                    ฿
                                    {{
                                      Commas(
                                        item.products.reduce(
                                          (acc, ele) =>
                                            acc + ele.price * ele.quantity,
                                          0
                                        ) * details.rate_yuan
                                      )
                                    }}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p class="mb-0 font-weight-bolder">
                                    {{ $t("shippingInchina") }}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    v-if="details.status === 1"
                                    class="mb-0 font-weight-bolder text-success d-flex align-items-center"
                                  >
                                    ¥
                                    <b-form-input
                                      v-model="item.shipping_cnfee"
                                      type="number"
                                      class="w-100 ml-25"
                                      @change="ChangePrice()"
                                    />
                                  </p>
                                  <p v-else>
                                    ¥
                                    {{
                                      Commas(
                                        item.shipping_cnfee
                                          ? item.shipping_cnfee.toFixed(2)
                                          : "0.00"
                                      )
                                    }}
                                  </p>
                                </td>
                                <td class="text-right">
                                  <p
                                    class="mb-0 font-weight-bolder text-primary"
                                  >
                                    ฿
                                    {{
                                      Commas(
                                        item.shipping_cnfee * details.rate_yuan
                                      )
                                    }}
                                  </p>
                                </td>
                              </tr>

                              <!-- <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('woodenCrates') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)) }}
                              </p>
                            </td>
                          </tr> -->

                              <!-- <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('ProductInspectionfee') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee), 0))
                                }}
                              </p>
                            </td>
                          </tr> -->

                              <!-- <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('key-138') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee), 0)) }}
                              </p>
                            </td>
                          </tr> -->

                              <!-- <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('cashOndelivery') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.cashOndelivery_fee ? item.cashOndelivery_fee.toFixed(2) : '0.00') }}
                              </p>
                            </td>
                          </tr> -->
                              <tr>
                                <td>
                                  <p class="mb-0 font-weight-bolder">
                                    {{ $t("totalPrices") }}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    class="mb-0 font-weight-bolder text-success"
                                  >
                                    ¥
                                    {{
                                    Commas((item.products.reduce((acc, ele)
                                      => acc + (ele.price * ele.quantity), 0)) + item.postnumber.reduce((acc, ele) => acc
                                        +
                                        (ele.packaging_fee), 0)
                                      +
                                      item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee),
                                        0) + item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee),
                                          0) + (Number(item.cashOndelivery_fee) + (Number(item.shipping_cnfee))))
                                    }}
                                  </p>
                                </td>
                                <td class="text-right">
                                  <p
                                    class="mb-0 font-weight-bolder text-primary"
                                  >
                                    ฿
                                    {{
                                      Commas(
                                        item.products.reduce(
                                          (acc, ele) =>
                                            acc + ele.price * ele.quantity,
                                          0
                                        ) *
                                          details.rate_yuan +
                                          item.postnumber.reduce(
                                            (acc, ele) =>
                                              acc + ele.packaging_fee,
                                            0
                                          ) +
                                          item.postnumber.reduce(
                                            (acc, ele) =>
                                              acc + ele.product_inspection_fee,
                                            0
                                          ) +
                                          item.postnumber.reduce(
                                            (acc, ele) => acc + ele.re_wrap_fee,
                                            0
                                          ) +
                                          (Number(item.cashOndelivery_fee) +
                                            Number(item.shipping_cnfee) *
                                              details.rate_yuan)
                                      )
                                    }}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <table v-if="item.show">
                          <thead>
                            <tr>
                              <th class="p-1">สถานะ : ไม่ได้สั่งซื้อ</th>
                            </tr>
                          </thead>
                        </table>
                        <table
                          v-if="
                            details.status !== 1 &&
                            details.status !== 2 &&
                            details.status !== 3 &&
                            !item.show
                          "
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th class="p-1">
                                {{ $t("parcelCode") }}
                                <b-button
                                  variant="success"
                                  size="sm"
                                  class="btn-icon rounded-circle mr5 sq-30"
                                  @click="PushPostnumber(index_shop)"
                                >
                                  <i class="fas fa-plus" />
                                </b-button>
                              </th>

                              <th class="text-center">
                                {{ $t("productLot") }}
                              </th>

                              <th class="text-center">
                                {{ $t("arrivedChinaWarehouse") }}
                              </th>

                              <th class="text-center">
                                {{ $t("leftChinaWarehouse") }}
                              </th>

                              <th class="text-center">
                                {{ $t("arrivedThaiWarehouse") }}
                              </th>

                              <th class="text-center">
                                {{ $t("completeWarehouseThaiExit") }}
                              </th>

                              <th class="text-center">
                                {{ $t("x-x") }}
                              </th>

                              <th class="text-center">
                                {{ $t("weight") }}({{ $t("key-116") }})
                              </th>

                              <th class="text-center">
                                {{ $t("amount") }}
                              </th>

                              <th class="text-center">
                                <!-- {{ $t('thb') }} -->
                                ค่านำเข้าจีน-ไทย
                              </th>

                              <!-- <th class="text-center">
                            {{ $t('thb-0') }}
                          </th> -->
                            </tr>
                          </thead>
                          <tbody
                            v-for="(itpost, indexP) in item.postnumber"
                            :key="indexP"
                          >
                            <!-- {{ item.postnumber[indexP] }} -->
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div>
                                    <input
                                      v-model="itpost.postnumber"
                                      class="form-control mt-1"
                                      type="text"
                                      @change="
                                        CheckPostNumber(
                                          itpost,
                                          indexP,
                                          index_shop
                                        )
                                      "
                                    />
                                  </div>

                                  <b-button
                                    variant="danger"
                                    size="sm"
                                    class="btn-icon rounded-circle mr5 sq-30 ml-1 mt-1"
                                    @click="
                                      RemovePostNumber(index_shop, indexP)
                                    "
                                  >
                                    <i class="fal fa-trash-alt" />
                                  </b-button>

                                  <i
                                    v-if="itpost.postnumber"
                                    v-clipboard:copy="itpost.postnumber"
                                    :style="{ fontSize: '20px' }"
                                    :class="
                                      iconClass[itpost.postnumber]
                                        ? 'fal fa-clipboard-check text-success mt-1'
                                        : 'fal fa-copy text-primary  mt-1'
                                    "
                                    @click="handleCopy(itpost.postnumber)"
                                  />
                                </div>
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.lot_name
                                    ? `${itpost.lot_name}/${itpost.lot_order}`
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.come_chinesewarehouse
                                    ? itpost.come_chinesewarehouse
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.out_chinesewarehouse
                                    ? itpost.out_chinesewarehouse
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.come_thaiwarehouse
                                    ? itpost.come_thaiwarehouse
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.out_thaiwarehouse
                                    ? itpost.out_thaiwarehouse
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{ itpost.width ? itpost.width : "-" }}x{{
                                  itpost.long ? itpost.long : "-"
                                }}x{{ itpost.height ? itpost.height : "-" }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.weight
                                    ? Commas(
                                        itpost.weight * itpost.product_amount
                                      )
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.charge_cue
                                    ? CommasCue(
                                        itpost.charge_cue *
                                          itpost.product_amount
                                      )
                                    : "-"
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  itpost.charge_per_save
                                    ? Commas(itpost.charge_per_save)
                                    : "-"
                                }}
                              </td>
                              <!-- <td class="text-center">
                            {{ itpost.charge_per_cue ? Commas(itpost.charge_per_cue) : '-' }}
                          </td> -->
                            </tr>
                          </tbody>
                        </table>
                        <!-- table2 -->
                        <div
                          class="d-flex justify-content-center align-items-center mt-2 mx-2"
                        >
                          <div
                            v-for="(pay, indexP) in item.payment"
                            :key="indexP"
                          >
                            <thead>
                              <tr>
                                <th class="text-center py-1 px-2">
                                  {{ $t("exportList") }}
                                </th>
                                <th class="text-center">{{ $t('transactionDate') }}</th>
                                <th class="text-center"> {{ $t("exportDate") }}</th>
                                <th class="text-center">{{ $t('deliveryType') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-center px-2">
                                  <b-link
                                    :to="
                                      pay.out_thaiwarehouse
                                        ? {
                                            name: 'admin-export_edit',
                                            params: { id: pay._id },
                                          }
                                        : {
                                            name: 'shipment_payment_edit',
                                            params: { id: pay._id },
                                          }
                                    "
                                    target="_blank"
                                  >
                                    <button class="buttonpay">
                                      {{ pay.itemnumber_text }}
                                    </button>
                                  </b-link>
                                </td>
                                <td class="text-center px-2">
                                  {{
                                    time(pay.created_at)
                                      ? time(pay.created_at)
                                      : "-"
                                  }}
                                </td>
                                <td class="text-center px-2">
                                  {{
                                    time(pay.out_thaiwarehouse)
                                      ? time(pay.out_thaiwarehouse)
                                      : "-"
                                  }}
                                </td>
                                <td class="text-center px-2">
                                  {{
                                    pay.typedelivery.pro_name
                                      ? pay.typedelivery.pro_name
                                      : "-"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </div>
                        </div>

                        <div class="mt-2 d-flex align-items-center">
                          <b-button
                            variant="primary"
                            size="sm"
                            class="btn-icon rounded-circle mr5 sq-30"
                            @click="showRemark(index_shop)"
                          >
                            <i class="fas fa-comment-alt-exclamation" />
                          </b-button>

                          <b-button
                            variant="danger"
                            size="sm"
                            class="btn-icon rounded-circle mr5 sq-30"
                            @click="RemoveShop(index_shop)"
                          >
                            <i class="fal fa-trash-alt" />
                          </b-button>

                          <p class="mb-0 font-weight-bolder">
                            {{ $t("remarks") }} :
                            {{
                              details.shops[index_shop].remark_shops
                                ? details.shops[index_shop].remark_shops
                                : ""
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 py-1">
                  <div class="card-total">
                    <p class="font-weight-bolder">
                      {{ $t("shopCount") }}
                    </p>
                    <h3 class="font-weight-bolder">
                      {{ Commas(details.shops.length) }}
                    </h3>
                  </div>
                </div>

                <div class="col-md-3 py-1">
                  <div class="card-total">
                    <p class="font-weight-bolder">
                      {{ $t("numberOfproducts") }}
                    </p>
                    <h3 class="font-weight-bolder">
                      {{
                        Commas(
                          details.shops.reduce(
                            (acc, shop) =>
                              acc +
                              Number(
                                shop.products.length > 0
                                  ? shop.products.reduce(
                                      (bf, ele) => bf + ele.quantity,
                                      0
                                    )
                                  : 0
                              ),
                            0
                          )
                        )
                      }}
                    </h3>
                  </div>
                </div>

                <div class="col-md-3 py-1">
                  <div class="card-total">
                    <p class="font-weight-bolder">
                      {{ $t("exRate") }}
                    </p>
                    <h3 class="font-weight-bolder">
                      {{ details.rate_yuan }}
                    </h3>
                  </div>
                </div>

                <div class="col-md-3 py-1">
                  <div class="card-total">
                    <p class="font-weight-bolder">
                      {{ $t("key-66") }} [¥ {{ $t("key-143") }}]
                    </p>
                    <h3 class="font-weight-bolder">
                      ¥
                      {{
                        Commas(
                          details.shops.reduce(
                            (acc, shop) =>
                              acc +
                              (shop.products.reduce(
                                (bf, ele) => bf + ele.price * ele.quantity,
                                0
                              ) +
                                shop.shipping_cnfee),
                            0
                          )
                        )
                      }}
                    </h3>
                  </div>
                </div>

                <!-- <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  ยอดรวมสินค้าทั้งหมด [฿ เงินบาท]
                </p>
                <h3 class="font-weight-bolder">
                  ฿ {{ Commas(details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf + (ele.price * ele.quantity), 0)), 0) * details.rate_yuan ) }}
                </h3>
              </div>
            </div> -->
              </div>

              <div class="row mt-2">
                <div class="col-md-6" />
                <div class="col-md-6">
                  <div class="card-info">
                    <h4 class="font-weight-bolder">
                      {{ $t("summaryListofexpenses") }}
                    </h4>
                    <hr />

                    <!-- <div>
                  <h4 class="font-weight-bolder">
                    {{ $t('specialServicecosts') }}
                  </h4>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('shippingInchina') }} ({{ $t('key-136') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(shipping_cnfee = details.shops.reduce((acc, shop) => acc + shop.shipping_cnfee, 0) *
                        details.rate_yuan) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('woodenCrates') }} ({{ $t('key-136') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(packaging_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                        shop.packaging_fee, 0)).reduce((re, res) => re + res, 0)) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('ProductInspectionfee') }} ({{ $t('key-136') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(product_inspection_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop)=> acc + shop.product_inspection_fee, 0)).reduce((re, res) => re + res, 0)) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('key-138') }} ({{ $t('key-136') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(re_wrap_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                        shop.re_wrap_fee, 0)).reduce((re, res) => re + res, 0)) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('key-139') }} ({{ $t('key-136') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(cashOndelivery_fee = details.shops.reduce((acc, shop) => acc +
                                                                              shop.cashOndelivery_fee,
                                                                            0)) }}
                    </p>
                  </div>
                </div> -->

                    <div class="d-flex justify-content-between">
                      <p>{{ $t("key-135") }} ({{ $t("key-136") }})</p>

                      <p class="font-weight-bolder">
                        ฿
                        {{ Commas(totalall = details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele)
                          =>
                          bf +
                          (ele.price * ele.quantity), 0)), 0) * details.rate_yuan) }}
                      </p>
                    </div>

                    <p class="mb-0">
                      {{ $t("useDiscountCoupon") }} : {{ $t("none") }}
                    </p>

                    <hr />

                    <div class="d-flex justify-content-between">
                      <h3 class="font-weight-bolder">
                        {{ $t("key-66") }}
                      </h3>

                      <h3 class="font-weight-bolder text-primary">
                        {{
                          Commas(
                            details.shops.reduce(
                              (acc, shop) => acc + shop.shipping_cnfee,
                              0
                            ) *
                              details.rate_yuan +
                              packaging_fee +
                              product_inspection_fee +
                              re_wrap_fee +
                              cashOndelivery_fee +
                              totalall
                          )
                        }}
                        {{ $t("baht") }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t("relatedAmountList") }}
                </h3>
              </div>

              <div class="pl-2 pr-2">
                <b-table
                  responsive="sm"
                  :fields="fieldsRelevant"
                  :items="Transaction"
                  show-empty
                >
                  <template #empty="">
                    <b-table-empty-slot>
                      <div class="text-center my-2">
                        <img
                          src="@/assets/images/anan-img/svg/doc_empty.svg"
                          alt="empty"
                          height="60px"
                        />
                        <p class="text-muted">No Data</p>
                      </div>
                    </b-table-empty-slot>
                  </template>

                  <template #cell(amount)="data">
                    <span
                      :style="
                        getAmountStyle(data.item.type, data.item.type_amount)
                      "
                    >
                      {{
                        getAmountPrefix(data.item.type, data.item.type_amount)
                      }}
                      {{ Commas(data.item.amount.toFixed(2)) }} ฿
                    </span>
                  </template>
                </b-table>
                <b-col md="12" class=" " v-if="details.status === 2">
                  <div
                    class="card p-2 mt-2 bg-blue d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/waiting-for-payment-order.png"
                      alt=""
                      width="60%"
                      height="60%"
                      style="
                        border-radius: 10px;
                        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
                      "
                    />
                  </div>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>
    <b-modal
      ref="my-modal"
      :title="$t('orderRate')"
      size="sm"
      @ok="ChangePrice()"
    >
      <b-form-group>
        <label for="rate">{{ $t("exRate") }}</label>
        <b-form-input
          id="rate"
          v-model="details.rate_yuan"
          type="number"
          :placeholder="$t('exRate')"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-remark"
      :title="$t('key-140')"
      size="sm"
      @ok="UpdateDetail()"
    >
      <b-form-group>
        <label for="remark">{{ $t("remarks") }}</label>
        <b-form-textarea id="remark" v-model="remark_shops" type="text" />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-remark2"
      :title="$t('key-144')"
      size="sm"
      @ok="UpdateDetail()"
    >
      <b-form-group>
        <label for="remark">{{ $t("remarks") }}</label>
        <b-form-textarea id="remark" v-model="remark_product" type="text" />
      </b-form-group>
    </b-modal>

    <b-modal ref="my-color" :title="$t('key-141')" size="sm" hide-footer>
      <div class="d-flex justify-content-center align-items-center">
        <div
          style="
            width: 50px;
            height: 50px;
            background-color: rgb(255, 255, 255);
            border: 1px solid#ccc;
          "
          class="mr-1"
          @click="selectedColor('rgb(255, 255, 255)')"
        />

        <div
          style="
            width: 50px;
            height: 50px;
            background-color: rgb(241, 241, 241);
          "
          class="mr-1"
          @click="selectedColor('rgb(241, 241, 241)')"
        />

        <div
          style="
            width: 50px;
            height: 50px;
            background-color: rgb(209, 231, 251);
          "
          class="mr-1"
          @click="selectedColor('rgb(209, 231, 251)')"
        />

        <div
          style="
            width: 50px;
            height: 50px;
            background-color: rgb(230, 253, 205);
          "
          class="mr-1"
          @click="selectedColor('rgb(230, 253, 205)')"
        />

        <div
          style="
            width: 50px;
            height: 50px;
            background-color: rgb(255, 231, 231);
          "
          class="mr-1"
          @click="selectedColor('rgb(255, 231, 231)')"
        />
      </div>
    </b-modal>

    <b-modal
      ref="my-payment"
      :title="$t('and')"
      size="md"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      @ok="SubmitMorePayment()"
    >
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1"> {{ $t("remarks") }} : </span>
          </div>
          <div class="w-75">
            <b-form-textarea
              id="remark"
              v-model="MorePayment.remark"
              type="text"
            />
          </div>
        </div>

        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              <small class="text-danger">*</small> {{ $t("type") }} :
            </span>
          </div>
          <div class="w-75">
            <div class="demo-inline-spacing mt-n1">
              <b-form-radio
                v-model="MorePayment.payment_type"
                name="some-radios"
                :value="1"
              >
                {{ $t("saveMoremoney") }}
              </b-form-radio>
              <b-form-radio
                v-model="MorePayment.payment_type"
                name="some-radios"
                :value="2"
              >
                {{ $t("Refunds") }}
              </b-form-radio>
            </div>
          </div>
        </div>

        <div
          v-if="MorePayment.payment_type === 2"
          class="d-flex align-items-center pt-1"
        >
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              <small class="text-danger">*</small> {{ $t("key-242") }} :
            </span>
          </div>
          <div class="w-75">
            <b-input-group
              class="input-group-merge w-75"
              :append="$t('quantity')"
            >
              <b-form-input
                id="h-MorePayment-amount"
                v-model="MorePayment.unrecevied"
                type="number"
                @input="
                  MorePayment.amount =
                    MorePayment.unrecevied > 0
                      ? (
                          MorePayment.unrecevied *
                          MorePayment.perunit *
                          details.rate_yuan
                        ).toFixed(2)
                      : 0
                "
              />
            </b-input-group>
          </div>
        </div>

        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              <small class="text-danger">*</small> {{ $t("amountOfmoney") }} :
            </span>
          </div>
          <div class="w-75">
            <b-input-group class="input-group-merge w-75" append="฿">
              <b-form-input
                id="h-MorePayment-amount"
                v-model="MorePayment.amount"
                type="number"
                :state="MorePayment.amount < 1 ? false : null"
              />
            </b-input-group>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-remark_cancel"
      :title="$t('remarks')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok="submitAddNew()"
    >
      <b-form-group>
        <!-- <validation-provider
            #default="{ errors }"
            name="detail"
            rules="required"
          > -->
        <label for="remark_cancel">{{ $t("details") }} :</label>
        <b-form-textarea
          id="remark_cancel"
          v-model="remark_cancel"
          :placeholder="$t('remarks')"
        />
        <!-- :state="errors.length > 0 ? false:null" -->
        <!-- <span class="text-danger">{{ errors[0] ? $t('key-24'): '' }}</span> -->
        <!-- </validation-provider> -->
      </b-form-group>
    </b-modal>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />

    <b-row>
      <b-col md="12" v-if="details.status === 1">
        <div
          class="card p-2 mt-2 bg-blue d-flex justify-content-center align-items-center"
        >
          <img
            src="/waiting-for-verification-order.png"
            alt=""
            width="60%"
            height="60%"
            style="
              border-radius: 10px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
            "
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12" v-if="details.status === 5">
        <div
          class="card p-2 mt-2 bg-blue d-flex justify-content-center align-items-center"
        >
          <img
            src="/trackanan.png"
            alt=""
            width="60%"
            height="60%"
            style="
              border-radius: 10px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
            "
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12" v-if="details.status === 4">
        <div
          class="card p-2 mt-2 bg-blue d-flex justify-content-center align-items-center"
        >
          <img
            src="/ordercompleted.png"
            alt=""
            width="60%"
            height="60%"
            style="
              border-radius: 10px;
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
            "
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BLink,
  BBadge,
  // BFormSelect,
  BFormCheckbox,
  BButton,
  VBTooltip,
  BTable,
  BInputGroup,
  BFormRadio,
  BOverlay,
} from "bootstrap-vue";
import moment from "moment-timezone";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import "vue-context/dist/css/vue-context.css";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    // BFormSelect,
    BLink,
    BFormCheckbox,
    BButton,
    vSelect,
    BTable,
    BInputGroup,
    BFormRadio,
    BOverlay,
    VueImageLightboxCarousel,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      iconClass: {},
      userData: JSON.parse(localStorage.getItem("userData")),
      indexShop: null,
      indexProduct: null,
      checked: null,
      rate: null,
      color: null,
      name: "",
      category: "",
      code: "",
      details: "",
      type: null,
      totalBaht: {},
      totalYen: {},
      totalproduct: 0,
      totalquantity: 0,

      itemsRelevant: [],
      option: [
        { title: "A", value: "A" },
        { title: "B", value: "B" },
        { title: "C", value: "C" },
      ],
      typesOp: [],
      remark_shops: "",
      remark_product: "",
      selectedFiles: [],
      uploadedImages: [],
      fields: [
        {
          key: "create_date",
          label: "วันที่ทำรายการ",
          thStyle: { width: "15%" },
        },
        { key: "detail", label: "รายละเอียด", thStyle: { width: "70%" } },
        { key: "amount", label: "จำนวน", thStyle: { width: "15%" } },
      ],
      items: [
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
      ],
      ProductShop: [],
      ShopList: [],
      itemRelevant: [],
      NewShop: 1,
      Transaction: [],
      shipping_cnfee: 0,
      packaging_fee: 0,
      product_inspection_fee: 0,
      re_wrap_fee: 0,
      cashOndelivery_fee: 0,
      totalall: 0,
      PostNumberArr: [],
      MorePayment: {
        remark: null,
        payment_type: 1,
        amount: 0,
        unrecevied: 0,
        perunit: 0,
        shop_id: null,
        product_id: null,
        sequence: null,
      },
      showOver: false,
      images: [],
      showLightbox: false,
      remark_cancel: null,
      cancel_by: null,
    };
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: "created_at",
          label: this.$t("transactionDate"),
          thStyle: { width: "20%" },
          formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        { key: "detail", label: this.$t("details"), thStyle: { width: "60%" } },
        {
          key: "amount",
          label: this.$t("quantity"),
          thStyle: { width: "20%" },
          formatter: (value) => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  async mounted() {
    this.GetOrderDetail();
    this.GetTransaction();
    // console.log(this.userData)
  },
  methods: {
    time(data) {
      if (data) {
        return moment(data).format("DD/MM/YYYY HH:mm:ss");
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    UpDateProduct(product_type, id, packbox, checkproduct) {
      const obj = {
        product_type: null,
        shop_id: id,
        order_id: this.$route.params.id,
        checkproduct,
        packbox,
      };
      if (product_type) {
        obj.product_type = this.typesOp.find(
          (ele) => ele.pro_id === product_type.pro_id
        );
      }
      this.$http.post("/order/update/postnumber", obj);
    },
    updateKeyword(id, note) {
      const params = {
        id,
        note,
      };
      this.$http
        .post("/order/updatenote/admin", params)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.Success(this.$t("key-260"));
        })
        .catch((error) => console.log(error));
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: picture };
      this.images.push(img);
    },
    showPicturePost(pictures) {
      this.showLightbox = true;
      pictures.forEach((picture) => {
        const img = { path: this.GetImg("postnumber_img", picture) };
        this.images.push(img);
      });
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    async Approve(data) {
      if (data) {
        if (data === 1) {
          this.details.inspector = this.userData.username;
        } else if (data === 6) {
          this.details.status = (await data) + 2;
          this.details.inspector = this.userData.username;
          this.UpdateDetail();
        }
        this.details.status = data + 1;
        this.SendEmail(this.details);
        this.UpdateDetail();
      }
    },
    SendEmail(data) {
      const obj = {
        status: data.status,
        uid: data.uid,
        username: data.username,
        email: null,
        orderId_text: `ORD-${data.orderId_text}`,
        agent_id: data.agent_id,
        fileimg: data.shops[0].products[0].picture,
        path_name: "order-detail",
        ref: data._id,
        created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
        Ref: `${data.username}#${data.orderId_text} ${
          data.delivery_type == 1 ? "" : "(SEA)"
        }${
          data.shops.some((ele) => ele.packbox === true) &&
          data.shops.some((ele) => ele.checkproduct === true)
            ? "(QC)(打木架)"
            : data.shops.some((ele) => ele.packbox === true)
            ? "(打木架)"
            : data.shops.some((ele) => ele.checkproduct === true)
            ? "(QC)"
            : ""
        }`,
        delivery_type: data.delivery_type,
        price_total: data.price_total,
      };
      this.$http
        .post("/order/SendEmail", obj)
        .then(() => {
          this.GetOrderDetail();
        })
        .catch((error) => console.log(error));
    },
    async CancelOrder() {
      const result = await this.$swal({
        title: '<h3 style="color: #141414">ยกเลิกรายการ</h3>',
        icon: "warning",
        showCancelButton: true,
        text: "คุณต้องยกเลิกรายการหรือไม่ ?",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      });

      if (result.value) {
        this.$bvModal.show("modal-remark_cancel");
        // this.details.status = 7
        // await this.UpdateDetail()
        // this.GetTransaction()
      } else {
        this.remark_cancel = null;
      }
    },
    async submitAddNew() {
      this.details.status = 7;
      this.details.cancel_remark = this.remark_cancel;
      this.details.cancel_by = this.userData.username;
      await this.UpdateDetail();
      this.GetTransaction();
    },
    UpdateDetail() {
      this.showOver = true;
      if (this.remark_shops && this.IndexRemark >= 0) {
        this.details.shops[this.IndexRemark].remark_shops = this.remark_shops;
      }
      if (
        this.indexShop >= 0 &&
        this.indexProduct >= 0 &&
        this.remark_product
      ) {
        this.details.shops[this.indexShop].products[
          this.indexProduct
        ].remark_product = this.remark_product;
      }
      const params = {
        data: this.details,
      };
      this.$http
        .post("/order/UpdateOrderById", params)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // this.Success(this.$t('key-260'))
          this.GetOrderDetail();
          this.remark_shops = null;
          this.IndexRemark = null;
          this.indexShop = null;
          this.indexProduct = null;
          this.remark_product = null;
          this.showOver = false;
          // console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
          this.showOver = false;
        });
    },
    ChangePrice() {
      const Product =
        this.details.shops.reduce(
          (acc, shop) =>
            acc +
            shop.products.reduce(
              (bf, ele) => bf + Number(ele.price) * Number(ele.quantity),
              0
            ),
          0
        ) * this.details.rate_yuan;
      const Shipping =
        this.details.shops.reduce(
          (acc, shop) => acc + Number(shop.shipping_cnfee),
          0
        ) * this.details.rate_yuan;
      this.details.price_total = Product + Shipping;
      this.details.quantity_total = this.details.shops.reduce(
        (acc, shop) =>
          acc +
          (shop.products.length > 0
            ? shop.products.reduce((bf, ele) => bf + Number(ele.quantity), 0)
            : 0),
        0
      );
      this.UpdateDetail();
    },
    GroupProduct() {
      const params = {
        uid: this.details.uid,
      };
      this.$http
        .get("/groupbyuser/show_user", { params })
        .then((response) => {
          if (response.data.success) {
            const Res = response.data.data;
            this.typesOp = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const items of Res.product_type.filter(
              (item) => item.product_type_class === this.details.delivery_type
            )) {
              this.typesOp.push({
                pro_id: items.product_type_id,
                pro_name: items.product_type_name,
                pro_class: items.product_type_class,
                pro_kilo: items.product_type_kilo,
                pro_queue: items.product_type_queue,
                pro_initial: items.product_type_initial,
                pro_name_en: items.product_type_name_en,
                pro_name_cn: items.product_type_name_cn,
              });
            }
            this.typesOp.sort((a, b) => {
              if (a.pro_name < b.pro_name) {
                return -1;
              }
              if (a.pro_name > b.pro_name) {
                return 1;
              }
              return 0;
            });
          }
        })
        .catch((error) => console.log(error));
    },
    async GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      };
      this.$http
        .post("/order/OrderById", params)
        .then((response) => {
          // console.log(response.data)

          this.details = response.data;
          console.debug(this.details);
          this.GroupProduct();
          this.details.created_at = moment(response.data.created_at).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.details.updated_at = moment(response.data.updated_at).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.CalMockShop();
          // eslint-disable-next-line
          for (const index in this.details.shops) {
            this.details.shops[index].postnumber = [];
            this.CalPostNumber(this.details.shops[index], index);
          }
        })
        .catch((error) => console.log(error));
    },
    async GetTransaction() {
      try {
        const params = {
          ref_id: this.$route.params.id,
        };
        const { data: res } = await this.$http.get("/transactions/show/ref", {
          params,
        });
        if (res.success) {
          this.Transaction = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true);

        setTimeout(() => {
          this.$set(this.iconClass, item, false);
        }, 3500);
      }
    },
    CalPostNumber(item, ShopIndex) {
      const FindShop = this.PostNumberArr.find(
        (ele) => ele.ShopIndex === ShopIndex
      );
      if (FindShop) {
        this.details.shops[ShopIndex].postnumber = FindShop.item;
      } else {
        this.GetPostNumber(item, ShopIndex);
      }
    },
    async GetPostNumber(item, ShopIndex) {
      try {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          shop_id: item._id,
        };
        const { data: res } = await this.$http.get("/postnumber/byshop", {
          params,
        });

        if (res.success) {
          this.details.shops[ShopIndex].postnumber = res.data;
          this.details.shops[ShopIndex].payment = res.payment;
          this.details = { ...this.details };
          const obj = { ShopIndex, item: res.data };
          this.PostNumberArr.push(obj);
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    PushPostnumber(index) {
      const Shop = this.details.shops[index];
      const NewObj = {
        agent_id: this.userData.agent_id, // ไอดีเอเจนต์
        uid: this.details.uid, // ไอดีลูกค้า
        username: this.details.username, // ยูสลูกค้า
        order_id: this.$route.params.id, // ไอดีออเดอร์
        order_text: this.details.orderId_text, // รหัสออเดอร์
        // eslint-disable-next-line no-underscore-dangle
        shop_id: Shop._id, // ไอดีออเดอร์
        lot_id: null, // // id lot
        lot_name: null,
        lot_order: null, // ลำดับlot
        lot_show: null, // 1 แสดง 0 ไม่แสดง
        tag_id: Shop.tag_id,
        postnumber: "",
        postnumber_type: 1,
        product_amount: Shop.products.length, // จำนวนสินค้า
        transportType: this.details.delivery_type, // ประเภทขนส่ง
        product_type: {}, // ประเภทสินค้า ค่าเริ่มต้น = null
        packbox: Shop.packbox, // ตีลังไม้ true = ตีลังไม้ , false = ไม่ตีลังไม้
        checkproduct: Shop.checkproduct, // เช็คสินค้า  true = เช็คสินค้า , false = ไม่เช็คสินค้า
        weight: 0, // น้ำหนัก ค่าเริ่มต้น = 0
        height: 0, // สูง ค่าเริ่มต้น = 0
        width: 0, // กว้าง ค่าเริ่มต้น = 0
        long: 0, // ยาว ค่าเริ่มต้น = 0
        charge_cue: 0,
        product_inspection_fee: 0, // ค่าเช็คสินค้า(฿) ค่าเริ่มต้น = 0
        shipping_fee: 0, // ค่าขนส่ง ค่าเริ่มต้น = 0
        re_wrap_fee: 0, // ค่าห่อใหม่(฿) ค่าเริ่มต้น = 0
        packaging_fee: 0, // ค่าตีลังไม้(฿) ค่าเริ่มต้น = 0
        other: 0, // ค่าอื่นๆ(฿) ค่าเริ่มต้น = 0
        charge_per_save: 0, // ราคาที่บันทึก(฿) ค่าเริ่มต้น = 0
        charge_per_kilo: 0, // คิดตามกิโล(฿) ค่าเริ่มต้น = 0
        charge_per_cue: 0, // คิดตามคิว(฿) ค่าเริ่มต้น = 0
        come_chinesewarehouse: null, // เข้าโกดังจีน ค่าเริ่มต้น = null
        out_chinesewarehouse: null, // ออกโกดังจีน ค่าเริ่มต้น = null
        come_thaiwarehouse: null, // เข้าโกดังไทย ค่าเริ่มต้น = null
        out_thaiwarehouse: null, // ออกโกดังไทย ค่าเริ่มต้น = null
        path_file: [], // เก็บชื่อไฟล์
        path_img: [], // เก็บชื่อรูป
        check_postnumber: true,
        warehouseTH_checked: false,
      };
      if (Shop.product_type) {
        NewObj.product_type = this.typesOp.find(
          (ele) => ele.pro_id === Shop.product_type.pro_id
        );
      }
      if (this.details.shops[index].postnumber) {
        this.details.shops[index].postnumber.push(NewObj);
      } else {
        this.details.shops[index].postnumber = [NewObj];
      }
      this.details = { ...this.details };
    },
    CalMockShop() {
      this.ShopList = [];
      this.ProductShop = [];
      // eslint-disable-next-line
      for (const shopIndex in this.details.shops) {
        if (this.details.shops[shopIndex].products.length === 0) {
          this.details.shops.splice(shopIndex, 1);
          this.UpdateDetail();
        }
        this.ShopList.push({
          value: this.details.shops[shopIndex].storename,
          text: `${Number(shopIndex) + 1}. ${
            this.details.shops[shopIndex].storename
          }`,
        });
        const ProArr = [];
        this.details.shops[shopIndex].products.forEach(() => {
          ProArr.push(this.details.shops[shopIndex].storename);
        });
        this.ProductShop.push(ProArr);
      }
      this.ShopList.push({ value: "newshop", text: "แยกร้านใหม่" });
      // console.log(this.ShopList)
      // console.log(this.ProductShop)
    },
    showRemark(index) {
      this.remark_shops = "";
      this.IndexRemark = index;
      if (this.details.shops[this.IndexRemark].remark_shops) {
        this.remark_shops = this.details.shops[this.IndexRemark].remark_shops;
      }
      this.$refs["my-remark"].show();
    },
    RemoveShop(index) {
      this.$swal({
        title: `<h3 style="color: #141414">${
          this.details.shops[index].show
            ? "ยกเลิก ไม่ได้สั่งซื้อ"
            : "ไม่ได้สั่งซื้อ"
        }</h3>`,
        icon: "warning",
        showCancelButton: true,
        text: `คุณต้องการ${
          this.details.shops[index].show
            ? "ยกเลิก ไม่ได้สั่งซื้อ"
            : "ไม่ได้สั่งซื้อ"
        } หรือไม่ ? `,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then((result) => {
        if (result.value) {
          if (this.details.shops[index].show) {
            this.details.shops[index].show = false;
          } else {
            this.details.shops[index].show = true;
          }
          // if (this.details.status === 1 || this.details.status === 2 || this.details.status === 3) {
          //   this.details.shops.splice(index, 1)
          // }
          // this.details.shops.splice(index, 1)
          this.ChangePrice();
          this.$swal({
            icon: "success",
            title: '<h3 style="color: #141414">สำเร็จ</h3>',
            html: `<p style="color: #141414">ทำรายการ${
              this.details.shops[index].show
                ? "ไม่ได้สั่งซื้อ"
                : "ยกเลิก ไม่ได้สั่งซื้อ"
            }สินค้าสำเร็จ</p>`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
        if (typeAmount) {
          return { color: "green" };
        }
        return { color: "red" };
      }
      if ([1, 8, 11, 12, 17, 16].includes(amount)) {
        return { color: "green" };
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
        return { color: "red" };
      }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
        if (typeAmount) {
          return "+";
        }
        return "-";
      }
      if ([1, 8, 11, 12, 17, 16].includes(value)) {
        return "+";
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
        return "-";
      }
    },
    RemovePostNumber(index, indexP) {
      this.$swal({
        title: '<h3 style="color: #141414">ลบรหัสพัสดุ</h3>',
        icon: "warning",
        showCancelButton: true,
        text: "คุณต้องลบรหัสพัสดุหรือไม่ ?",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-underscore-dangle
          if (this.details.shops[index].postnumber[indexP]._id) {
            // console.log(this.details.shops[index].postnumber[indexP])
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              ID: this.details.shops[index].postnumber[indexP]._id,
            };
            this.$http
              .post("/postnumber/remove", obj)
              .then((response) => {
                if (response.data.success) {
                  this.Success(this.$t("key-260"));
                  this.GetOrderDetail();
                }
              })
              .catch((error) => {
                console.log(error);
                this.SwalError();
              });
          }
          this.details.shops[index].postnumber.splice(indexP, 1);
          this.details = { ...this.details };

          // this.CheckPostNumber()
        }
      });
    },
    ChangeShop(InShop, InPro, value) {
      const OldShop = this.details.shops[InShop];
      const FindProduct = this.details.shops[InShop].products[InPro];
      if (value === "newshop") {
        this.details.shops.push({
          storename: `${OldShop.storename} (${this.NewShop})`,
          packbox: false, // ลังไม้
          checkproduct: false, // ตรวจสอบสินค้า
          provider: OldShop.provider, // ผู้ให้บริการ /1688, taobao, tmall,anancargo
          remark_shops: null,
          postnumber: [], // หมายเลขพัสดุ
          lot: null, // ล็อตสินค้า
          product_type: null, // ประเภทสินค้า
          come_chinesewarehouse: null, // เข้าโกดังจีน
          out_chinesewarehouse: null, // ออกโกดังจีน
          come_thaiwarehouse: null, // เข้าโกดังไทย
          out_thaiwarehouse: null, // ออกโกดังไทย
          width_length_height: null, // กว้างxยาวxสูง
          weight: 0, // น้ำหนัก
          quantity_cue: 0, // ปริมาณ(คิว)
          charge_per_kilo: 0, // คิดตามกิโล(฿)
          charge_per_cue: 0, // คิดตามคิว(฿)
          product_inspection_fee: 0, // ค่าเช็คสินค้า(฿) ค่าเริ่มต้น = 0
          packaging_fee: 0, // ค่าตีลังไม้(฿) ค่าเริ่มต้น = 0
          re_wrap_fee: 0, // ค่าห่อใหม่(฿) ค่าเริ่มต้น = 0
          cashOndelivery_fee: 0, // ค่าส่งเก็บเงินปลายทาง(฿) ค่าเริ่มต้น = 0
          shipping_cnfee: 0, // ค่าขนส่งจีน(฿) ค่าเริ่มต้น = 0
          products: [FindProduct],
        });
        this.details.shops[InShop].products.splice(InPro, 1);
        this.NewShop += 1;
      } else {
        const FindShop = this.details.shops.findIndex(
          (item) => item.storename === value
        );
        this.details.shops[FindShop].products.push(FindProduct);
        this.details.shops[InShop].products.splice(InPro, 1);
      }
      this.CalMockShop();
      this.UpdateDetail();
    },
    CheckPostNumber(item, index, shopIndex) {
      // eslint-disable-next-line no-param-reassign
      item.postnumber = this.checkInput(item.postnumber);
      // eslint-disable-next-line no-underscore-dangle
      if (item._id && item.postnumber) {
        this.$http
          .post("/postnumber/update", item)
          .then((response) => {
            if (response.data.success) {
              this.Success(this.$t("key-260"));
              this.GetOrderDetail();
            } else {
              this.SwalError(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
            this.SwalError();
          });
      } else if (item.postnumber) {
        this.$http
          .post("/postnumber/store", item)
          .then((response) => {
            if (response.data.success) {
              this.details.shops[shopIndex].postnumber[index] =
                response.data.data;
              this.details = { ...this.details };
              const FindShop = this.PostNumberArr.find(
                (ele) => ele.ShopIndex === shopIndex
              );
              if (FindShop) {
                FindShop.item = this.details.shops[shopIndex].postnumber;
              } else {
                const obj = {
                  ShopIndex: shopIndex,
                  item: this.details.shops[shopIndex].postnumber,
                };
                this.PostNumberArr.push(obj);
              }
              this.Success(this.$t("key-260"));
              this.GetOrderDetail();
            } else {
              this.SwalError(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
            this.SwalError();
          });
      }
    },
    checkInput(item) {
      if (/\s/.test(item) || /\t/.test(item)) {
        return item.replace(/\s/g, "").replace(/\t/g, "");
      }
      return item;
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    showPayment(indexShop, indexProduct) {
      // colorfield
      this.indexShop = indexShop;
      this.indexProduct = indexProduct;
      // eslint-disable-next-line no-underscore-dangle
      this.MorePayment.shop_id = this.details.shops[indexShop]._id;
      // eslint-disable-next-line no-underscore-dangle
      this.MorePayment.product_id =
        this.details.shops[indexShop].products[indexProduct]._id;
      const Product = this.details.shops[indexShop].products[indexProduct];
      this.MorePayment.perunit = Number(Product.price);
      if (Product.more_payment && Product.more_payment.remark) {
        this.MorePayment.remark = Product.more_payment.remark;
      }
      this.MorePayment.sequence = `${indexShop + 1}.${indexProduct + 1}`;
      this.$refs["my-payment"].show();
    },
    SubmitMorePayment() {
      this.MorePayment.ID = this.$route.params.id;
      console.log(this.MorePayment);
      this.$http
        .post("/order/morepayment", this.MorePayment)
        .then(() => {
          this.Success(this.$t("key-260"));
          this.GetOrderDetail();
          this.GetTransaction();
        })
        .catch((error) => {
          console.log(error);
          this.SwalError();
        });
    },
    showColor(indexShop, indexProduct) {
      // colorfield
      this.indexShop = indexShop;
      this.indexProduct = indexProduct;
      this.$refs["my-color"].show();
    },
    selectedColor(color) {
      // change color by index
      this.details.shops[this.indexShop].products[
        this.indexProduct
      ].colorfield = color;
      this.UpdateDetail();
      this.$refs["my-color"].hide();
    },
    addAddr() {
      this.$refs.addRules.validate().then((success) => {
        if (success) {
          this.$bvModal.hide("add-addr");
        }
      });
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    showRemark2(indexShop, indexProduct) {
      this.remark_product = "";
      this.indexShop = indexShop;
      this.indexProduct = indexProduct;
      if (this.details.shops[indexShop].products[indexProduct].remark_product) {
        this.remark_product =
          this.details.shops[indexShop].products[indexProduct].remark_product;
      }
      this.$refs["my-remark2"].show();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-context.scss";
</style>
