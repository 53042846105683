<template>
  <b-overlay :show="show" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> {{ $t("search") }}
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">{{ $t("searchType") }}</label>
              <b-form-select v-model="search_type" :options="search_type_option" :placeholder="$t('key-39')" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input id="username" v-model="search_val" :disabled="!search_type" type="text" class="form-control"
                :placeholder="$t('searchData')" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">{{ $t("key-40") }}</label>
              <flat-pickr v-model="dateStart" class="form-control" :placeholder="$t('selectStartTime')" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">{{ $t("key-41") }}</label>
              <flat-pickr v-model="dateEnd" class="form-control" :placeholder="$t('selectEndTime')" />
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label for="username">{{ $t('selectBank') }}</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                :placeholder="$t('selectBank')"
              />
            </div>
          </div> -->
          <div class="col-md-3">
            <button class="btn btn-gradient-dark" @click="getDeposit()">
              <feather-icon icon="SearchIcon" />
              {{ $t("search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <b-form-group class="mr-1 mb-md-0">
            <b-button v-b-modal.modal-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" block>
              {{ $t("topUp") }}
            </b-button>
          </b-form-group>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <h2 class="text-dark font-medium-1 mr-2">
                ตรวจสอบสลิปอัตโนมัติ
              </h2>
            </div>
            <b-form-checkbox v-model="active.active" class="custom-control-success" switch @change="Checkeditem(active)">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right text-danger">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </b-card-body>

      <b-table small striped hover responsive show-empty class="position-relative items-center" :per-page="perPage"
        :items="items" :fields="fields">
        <template #cell(index)="data">
          {{ data.item.itemnumber_text }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px" />
            {{
              scope.emptyFiltered ? "No matching records found" : $t("key-52")
            }}
          </div>
        </template>
        <template #cell(fileimg)="data">
          <img class="img-slip" :src="GetImg('refillslip', data.item.fileimg)"
            @click="showPicture(data.item.fileimg)" />
          <!--
          <button @click="showLightbox = true">
            Open Lightbox
          </button> -->
          <vue-image-lightbox-carousel ref="lightbox" :show="showLightbox" :images="images" :show-caption="false"
            @close="closeBox()" />
        </template>
        <template #cell(before_balance)="data">
          {{
            data.item.before_balance ? Commas(data.item.before_balance) : 0.0
          }}
        </template>
        <template #cell(after_balance)="data">
          <p class="font-weight-bolder text-success mb-0">
            {{
              data.item.after_balance ? Commas(data.item.after_balance) : 0.0
            }}
          </p>
        </template>
        <template #cell(amount)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.amount ? Commas(data.item.amount) : 0.0 }}
          </p>
        </template>
        <template #cell(addby_name)="data">
          <!-- <div> -->
          <b-badge v-if="data.item.addby_name === 'Auto Kbank'" variant="success">
            <small>{{ data.item.addby_name }}</small>
            <br />
            <small>{{ data.item.date_approve }}</small>
          </b-badge>
          <b-badge v-else-if="data.item.addby_name" variant="warning">
            <small>{{ data.item.addby_name }}</small>
            <br />
            <small>{{ data.item.date_approve }}</small>
          </b-badge>
          <!-- </div> -->
        </template>
        <template #cell(name_account)="data">
          {{ data.item.name_account ? data.item.name_account: '-' }}
        </template>
        <template #cell(accountbank)="data">
          <img
            v-if="data.item.accountbank"
            :src="`/bankIcon/${data.item.accountbank.path_photo}`"
            :alt="data.item.accountbank.bankname"
            height="30"
            class="mr-50 rounded-sm"
          />
          <br />
          <small>{{data.item.accountbank ? data.item.accountbank.accNumber: '-' }}</small>
        </template>
        <template #cell(from)="data">
          <div v-if="data.item.bank_code">
            <img
              :src="`${
                itemBankList.find((ele) => ele.bankcode === data.item.bank_code)
                  .path_photo
              }`"
              :alt="`${
                itemBankList.find((ele) => ele.bankcode === data.item.bank_code)
                  .name
              }`"
              height="30"
              class="mr-50 rounded-sm"
            />
            <br />
            <small>{{ data.item.number_account }}</small>
          </div>
          <div v-else>-</div>
        </template>
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-button v-b-tooltip.hover.v-info :title="$t('details')" variant="gradient-info" size="sm" @click="
              $router.push({
                name: 'Deposit-Withdraw',
                params: { id: data.item.username },
              })
              ">
              <i class="fas fa-address-card" />
            </b-button>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge v-if="data.item.status === 'cancel'" variant="light-danger">
              {{ data.item.status }}
            </b-badge>
            <b-badge v-if="data.item.status === 'waiting'" variant="light-warning">
              {{ data.item.status }}
            </b-badge>
            <b-badge v-if="data.item.status === 'success'" variant="light-success">
              {{ data.item.status }}
            </b-badge>
            <br />
            <b-badge @click="itemsamount = data.item, ShowRefill()"> เติมเงินย้อนหลัง </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.status === 'waiting'"
            v-b-tooltip.hover.v-danger
            :title="$t('approve')"
            variant="gradient-success"
            size="sm"
            style="width: 70px"
            :disabled="isDisabled"
            @click="ApproveList(data.item)"
          >
            {{ $t("approve") }}
          </b-button>
          &nbsp;&nbsp;&nbsp;
          <b-button
            v-b-tooltip.hover.v-dark
            :title="$t('cancel')"
            variant="gradient-danger"
            size="sm"
            style="width: 70px; margin-top: 5px"
            :disabled="isDisabled"
            @click="CancelList(data.item)"
          >
            {{ $t("cancel") }}
          </b-button>

          <small class="text-danger">
            {{
              data.item.remark_admin
                ? `*หมายเหตุ ${data.item.remark_admin}`
                : ""
            }}</small>
          <!-- <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'waiting'"
              variant="light-primary"
              @click="ApproveList(data.item)"
            >
              {{ $t('ApproveList') }}
            </b-badge>
          </div>
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'success'"
              variant="light-danger"
              @click="CancelList(data.item)"
            >
              {{ $t('cancelOrder') }}
            </b-badge>
          </div> -->
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getDeposit()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="getDeposit()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal id="modal-1" :title="$t('topUp')" :ok-title="$t('topUp')" @ok="submit">
        <b-overlay :show="show2" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">กรุณารอสักครู่...</p>
            </div>
          </template>
          <validation-observer ref="simpleRules">
            <div class="d-flex">
              <b-form-input id="vi-first-name" v-model="tel" class="col-9 mr-1" name="tel"
                :placeholder="$t('key-42')" />
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="col-2.5 btn-icon" block
                @click="getUsersSearch()">
                <feather-icon icon="SearchIcon" />
                {{ $t("search") }}
              </b-button>
            </div>
            <div>
              <span v-if="!member && search === true" style="color: red">
                {{ $t("key-52") }}
              </span>
              <span v-if="member && search === true" style="color: green">
                {{ member.fullname }} : {{ member.username }}
              </span>
            </div>
            <br />

            <div class="row">
              <div class="col">
                <b-form-group :label="$t('key-43')" label-for="vi-first-name">
                  <validation-provider #default="{ errors }" name="amount" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <i class="fad fa-donate" />
                      </b-input-group-prepend>
                      <b-form-input id="vi-first-name" v-model="amount" name="amount" type="number"
                        :state="errors.length > 0 ? false : null" placeholder="" />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? $t("key-290") : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col">
                <div>
                  <label for="time">{{ $t("timeInSlip") }}</label>
                  <div class="d-flex align-items-center">
                    <flat-pickr v-model="dateNtim" class="form-control" :placeholder="$t('selectDateTime')"
                      :config="flatpickrConfig" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col">
                <div>
                  <label for="from">{{ $t("transferFromAccount") }}</label>
                  <div>
                    <b-form-select v-model="cus_bank" :options="cus_bank_list" />
                  </div>
                </div>
              </div> -->
              <div class="col">
                <div>
                  <label for="from">{{ $t("selectDepositAccount") }}</label>
                  <div>
                    <b-form-select v-model="from" :options="fromOP" />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <b-form-group :label="$t('slipAttachment')" label-for="h-files-name">
              <b-form-file v-model="pic" @change="handleFileUpload" />
              <br />
              <div><img height="100" :src="pic" /></div>
            </b-form-group>
          </validation-observer>
        </b-overlay>
      </b-modal>
      <b-modal id="modal-ref" :title="$t('remarks')" :ok-title="$t('save')" :cancel-title="$t('cancel')"
        @ok.prevent="CancelRef()">
        <b-form-group>
          <label for="amount">{{ $t("details") }} : </label>
          <b-form-input id="Amount" v-model="remarks" type="text" :placeholder="$t('remarks')" />
        </b-form-group>
      </b-modal>
      <b-modal id="modal-refill-amount" title="รายการเติมเงินย้อนหลัง" size="lg" hide-footer>
        <div class="mt-1">
          <b-table responsive="sm" :items="getamount" :fields="refillamount" show-empty>
            <template #cell(itemnumber_text)="data">
              <span class="text-primary">{{ data.item.itemnumber_text }}</span>
            </template>
            <template #cell(quantity)="data">
              <b-badge v-if="data.item.status === 'cancel'" variant="light-danger">
                {{ data.item.status }}
              </b-badge>
              <b-badge v-if="data.item.status === 'waiting'" variant="light-warning">
                {{ data.item.status }}
              </b-badge>
              <b-badge v-if="data.item.status === 'success'" variant="light-success">
                {{ data.item.status }}
              </b-badge>
              <br />
              {{ Commas(data.item.amount) }}
            </template>
            <template #cell(attachedFile)="data">
              <img class="img-slip" :src="GetImg('refillslip', data.item.fileimg)"
                @click="showPicture(data.item.fileimg)" />
            </template>
          </b-table>
          <div class="d-flex justify-content-end">
            <b-pagination v-model="currentPageamount" :total-rows="totalRowsamount" :per-page="perPageamount"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0" @input="ShowRefill()">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment-timezone";
import {
  BBadge,
  // BRow,
  // BCol,
  // BIconController,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import flatPickr from "vue-flatpickr-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import ThaiLocale from "flatpickr/dist/l10n/th";
// eslint-disable-next-line import/no-extraneous-dependencies
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    flatPickr,
    // BRow,
    BBadge,
    BFormFile,
    // BCol,
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // BIconController,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    VueImageLightboxCarousel,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      active: null,
      isDisabled: false,
      images: [],
      item: { id: null , active: false },
      showLightbox: false,
      lightboxIndex: 0,
      lightboxImages: [],
      search_type: null,
      search_val: null,
      time: null,
      time2: null,
      from: null,
      name: "",
      required,
      email,
      show: true,
      depositdata: [],
      member: "",
      amount: "",
      optionsMember: "",
      perPage: 20,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fromOP: [],
      timeOp1: [
        { value: "00", text: "00" },
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
      ],
      timeOp2: [
        { value: "00", text: "00" },
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" },
        { value: "32", text: "32" },
        { value: "33", text: "33" },
        { value: "34", text: "34" },
        { value: "35", text: "35" },
        { value: "36", text: "36" },
        { value: "37", text: "37" },
        { value: "38", text: "38" },
        { value: "39", text: "39" },
        { value: "40", text: "40" },
        { value: "41", text: "41" },
        { value: "42", text: "42" },
        { value: "43", text: "43" },
        { value: "44", text: "44" },
        { value: "45", text: "45" },
        { value: "46", text: "46" },
        { value: "47", text: "47" },
        { value: "48", text: "48" },
        { value: "49", text: "49" },
        { value: "50", text: "50" },
        { value: "51", text: "51" },
        { value: "52", text: "52" },
        { value: "53", text: "53" },
        { value: "54", text: "54" },
        { value: "55", text: "55" },
        { value: "56", text: "56" },
        { value: "57", text: "57" },
        { value: "58", text: "58" },
        { value: "59", text: "59" },
      ],

      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem("userData")),
      show2: false,
      tel: null,
      search: false,
      dateStart: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      dateEnd: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      fromOPs: [
        { value: null, text: "ทั้งหมด" },
        { value: "scb", text: "ธนาคารไทยพาณิชย์" },
        { value: "kbank", text: "ธนาคารกสิกรไทย" },
        { value: "truewallet", text: "truewallet" },
      ],
      search_from: null,
      MaxAmount: 0,
      pic: "",
      selectedFile: null,
      itemBankList: [],
      flatpickrConfig: {
        dateFormat: "Y-m-d  H:i",
        enableTime: true,
        time_24hr: true,
        locale: ThaiLocale.th,
      },
      dateNtim: null,
      cus_bank_list: [],
      cus_bank: null,
      remarks: null,
      ID: null,
      getamount: [],
      currentPageamount: 1,
      perPageamount: 5,
      totalRowsamount: 0,
      itemsamount: null
    };
  },
  computed: {
    search_type_option() {
      return [
        { value: 1, text: this.$t("username") },
        { value: 2, text: this.$t("key-48") },
        { value: 3, text: this.$t("amountOfmoney") },
      ];
    },
    fields() {
      return [
        {
          key: "index",
          label: "no.",
          thStyle: { width: "5%" },
          thClass: "text-center",
        },
        // { key: 'detail', label: this.$t('customerDetails') },
        { key: "username", label: this.$t("username") },
        { key: "name_account", label: this.$t("customerName") },
        { key: "file_date", label: this.$t("key-44") },
        { key: "before_balance", label: this.$t("beforeDeposit") },
        { key: "amount", label: this.$t("key-45") },
        // { key: 'bonus', label: this.$t('bonus') },
        { key: "after_balance", label: this.$t("totalCredit") },
        {
          key: "addby_name",
          label: this.$t("approvedBy"),
          thStyle: { width: "5%" },
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "accountbank",
          label: this.$t("bank"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        { key: "fileimg", label: this.$t("slip") },
        // {
        //   key: "from",
        //   label: this.$t("source"),
        //   thStyle: { width: "10%" },
        //   thClass: "text-center",
        //   tdClass: "text-center",
        // },
        { key: "status", label: "status" },
        {
          key: "created_at",
          label: "เวลา",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        { key: "actions", label: "Actions" },
      ];
    },
    refillamount() {
      return [
        {
          key: "created_at",
          label: this.$t("transactionDate"),
          thStyle: { width: "5%" },
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "itemnumber_text",
          label: this.$t("orderNumber"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "username",
          label: this.$t("customerCode"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "quantity",
          label: this.$t("quantity"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "attachedFile",
          label: this.$t("attachedFile"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUserslist()
  //     this.getDeposit()
  //   }, 30000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    this.getDeposit();
    this.getBankSelect();
    this.getBanklist();
    this.getCheck();
  },
  methods: {
    ShowRefill() {
      if (this.itemsamount) {
        const params = {
          amount: this.itemsamount.amount,
          page: this.currentPageamount,
          perPage: this.perPageamount,
        };
        this.$http
          .get("/StatementAdmin/GetAmount", { params })
          .then((response) => {
            if (response.data.status) {
              this.getamount = response.data.data;
              this.totalRowsamount = response.data.total
              this.$bvModal.show("modal-refill-amount");
            }
          })
          .catch((err) => {
            console.debug(err.response.data);
          });
      }
    },
    getDeposit() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        search_from: this.search_from,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      this.show = true;
      this.$http
        .get("/RefillAdmin/index", { params })
        .then((response) => {
          // console.log(response.data)
          this.show = false;
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.debug(this.items);
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    async getBanklist() {
      try {
        const { data: res } = await this.$http.get("/Refill/getBank");
        this.fromOP = res.map((ele) => ({
          value: ele,
          text: `${ele.bankname} (${ele.accNumber})`,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getCheck() {
      try {
        const { data: res } = await this.$http.get("apiInfo/getData"); 
        this.active = res;
      } catch (err) {
        console.log(err);
      }
    }, 
    async getBankSelect() {
      try {
        const { data: res } = await this.$http.get("/bank");
        this.itemBankList = res.map((ele) => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    clearData() {
      this.member = "";
      this.amount = "";
      this.pic = "";
      this.selectedFile = null;
      this.dateNtim = null;
      this.cus_bank_list = [];
      this.cus_bank = null;
      this.tel = null;
    },
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Image = reader.result;
          // Send the base64Image to the API using an HTTP request
          // Example:
          this.pic = base64Image;
        };
        reader.readAsDataURL(this.selectedFile);
      }
    },
    CancelList(data) {
      this.isDisabled = true;
      this.$swal({
        title: "ยืนยันที่จะยกเลิกรายการฝาก ?",
        text: `ของ ${data.username} จำนวน ${data.amount} บาท หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("key-13"),
        cancelButtonText: this.$t("cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // this.show = true
          this.$bvModal.show("modal-ref");
          // eslint-disable-next-line no-underscore-dangle
          this.ID = data._id;
          // const params = {
          //   // eslint-disable-next-line no-underscore-dangle
          //   ID: data._id,
          // }
        } else {
          this.$swal(this.$t("cancel"), this.$t("cancelOrder"), "error");
          this.isDisabled = false;
        }
      });
    },
    CancelRef() {
      this.show = true;
      const obj = {
        ID: this.ID,
        remark_admin: this.remarks,
      };
      this.$http
        .post("/Refill/CancelList", obj)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          if (response.data.status) {
            this.getDeposit();
            this.Success(this.$t("operationCompleted"));
            this.$bvModal.hide("modal-ref");
            this.show = false;
            this.isDisabled = false;
          }
        })
        .catch((error) => {
          this.SwalError(error.response.data.message);
          this.show = false;
          this.isDisabled = false;
        });
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: this.GetImg("refillslip", picture) };
      this.images.push(img);
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    ApproveList(data) {
      this.isDisabled = true;
      this.$swal({
        title: "ยืนยันที่จะอนุมัติรายการฝาก ?",
        text: `ของ ${data.name_account} เลขบัญชี ${data.number_account} จำนวน ${data.amount} บาท หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("key-13"),
        cancelButtonText: this.$t("cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          };
          this.$http
            .get("/Refill/AdminApprove", { params })
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              if (response.data.status) {
                this.getDeposit();
                this.Success(this.$t("operationCompleted"));
                this.show = false;
                this.isDisabled = false;
              }
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
              this.show = false;
              this.isDisabled = false;
            });
        } else {
          this.$swal(this.$t("cancel"), this.$t("cancelOrder"), "error");
          this.isDisabled = false;
        }
      });
    },
    getUsersSearch() {
      this.show2 = true;
      const obj = {
        tel: this.tel,
      };
      this.$http
        .post("/users/detailbank/search", obj)
        .then((response) => {
          this.member = response.data.Users;
          // this.cus_bank_list = response.data.BankData.map((ele) => ({
          //   value: ele,
          //   text: `${ele.name_bank} (${ele.number_account})`,
          // }));
          this.search = true;
          this.show2 = false;
        })
        .catch((error) => {
          console.log(error);
          this.show2 = false;
          this.search = true;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    submit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.dateNtim && this.pic) {
            this.show = true;
            const formData = {
              username: this.member,
              uid: this.member.id,
              amount: this.amount,
              time: this.dateNtim,
              bank: this.from,
              slip: this.pic,
              cus_bank: this.cus_bank,
            };
            this.$http
              .post("/RefillAdmin/store", formData)
              .then((response) => {
                if (response.data.status) {
                  this.show = false;
                  this.getDeposit();
                  this.time = null;
                  this.time2 = null;
                  this.member = "";
                  this.amount = "";
                  this.from = null;
                  this.clearData();
                  this.Success("เติมเครดิต สำเร็จ");
                }
              })
              .catch((error) => {
                this.show = false;
                this.time = null;
                this.time2 = null;
                this.member = "";
                this.amount = "";
                this.from = null;
                this.clearData();
                this.SwalError(error.response.data.message);
              });
          } else {
            this.show = false;
            this.SwalError("กรุณาใส่ให้ครบทุกช่อง");
          }
        } else {
          this.show = false;
          this.SwalError("กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง");
        }
      });
    },
    Checkeditem(data) {
      const obj = {
        id: data._id,
        active: data.active,
      };
      this.$http
        .post("/apiInfo/Status", obj)
        .then(() => {
          this.Success("บันทึกข้อมูลเรียบร้อย");
          this.getCheck(); 
        })
        .catch((err) => {
          console.log(err);
          this.SwalError(err); 
        });
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

  },
};
</script>

<style scoped>
table {
  width: 100%;
  color: #fff !important;
}

thead,
th,
tr {
  color: #fff default;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

th {
  color: #fff default;
  background-color: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
